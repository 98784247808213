<template>
  <div
    v-show="show"
    :class="[
      component.main,
      layoutClass,
      zIndex
    ]"
    class="modal-backdrop left-0 top-0 h-full w-full flex p-4 justify-center bg-transparent-dark overflow-auto z-[99]"
  >
    <atoms-card
      v-show="show"
      :id="component.id"
      v-click-outside="onClickOutside"
      class="card relative z-10 rounded-lg m-2"
      :size="component.cardSize"
      :class="[
        sizes[size],
        cardClass,
        {
          'w-full': fullWidthModal
        }
      ]"
    >
      <button
        v-if="enableCloseIcon"
        class="absolute right-3 top-3"
        :class="closeIconClass"
        @click="closeButtonAction"
      >
        <component
          :is="component.icon"
          v-bind="component.iconProps"
          :width="closeSizes[closeButtonSize]?.width ?? ''"
          :height="closeSizes[closeButtonSize]?.height ?? ''"
        />
      </button>

      <slot />
    </atoms-card>
  </div>
</template>

<script setup>
import { generateUID } from '~/helpers/functions'

const { devices } = deviceChecker()

defineOptions({
  name: 'MoleculesModal'
})

const props = defineProps({
  size: {
    type: String,
    default: 'standard'
  },

  cardSize: {
    type: String,
    default: 'md'
  },

  cardClass: {
    type: String,
    default: ''
  },

  fullPage: {
    type: Boolean,
    default: true
  },

  overlayCustomClass: {
    type: String,
    default: ''
  },

  enableCloseIcon: {
    type: Boolean,
    default: true
  },

  overlayClickable: {
    type: Boolean,
    default: true
  },

  closeAction: {
    type: Function,
    default: event => event
  },

  closeButtonSize: {
    type: String,
    default: 'standard'
  },

  closeIconCircle: {
    type: Boolean,
    default: true
  },

  closeIconClass: {
    type: String,
    default: ''
  },

  fullWidthModal: {
    type: Boolean,
    default: true
  }
})

const sizes = {
  small: 'md:w-76',
  large: 'md:w-96',
  xLarge: 'md:w-[30rem]',
  half: 'md:w-1/2 lg:1/3 sm:w-full'
}

const closeSizes = {
  standard: {
    width: '16',
    height: '16'
  },

  large: {
    width: '20',
    height: '20'
  },

  xl: {
    width: '32',
    height: '32'
  },

  full: {
    width: '100%',
    height: '100%'
  }
}

const show = ref(false)
const modalCardHeight = ref()

const component = computed(() => {
  const id = `modal-${generateUID()}`

  const main = (() => {
    return modalCardHeight.value >= devices.height.value
      ? 'items-start'
      : 'items-center'
  })()

  const cardSize = (() => {
    const temp = props.cardSize

    /*
    // TODO: Remove this block if not needed
    if (props.fullPage) {
      return 'standard'
    } */

    return temp
  })()

  const icon = props.closeIconCircle
    ? getComponent('IconsError')
    : getComponent('IconsClose')

  const iconProps = props.closeIconCircle
    ? {
      fill: '#bdbdbd'
    }
    : {}

  return {
    id,
    main,
    cardSize,
    icon,
    iconProps
  }
})

const layoutClass = computed(() => {
  return props.fullPage ? 'fixed' : 'absolute'
})

const zIndex = computed(() => {
  return props.fullPage ? 'z-40' : 'z-[1]'
})

onBeforeUnmount(() => {
  toggle(false)

  const body = document.querySelector('body')
  body.style.overflow = ''
})

const emit = defineEmits([
  'toggle'
])

const toggle = value => {
  if (typeof value === 'undefined') {
    show.value = !show.value
  } else {
    show.value = value
  }
  emit('toggle', show)
}

const closeButtonAction = () => {
  props.closeAction()
  toggle(false)
}

const onClickOutside = event => {
  // Accept clicks only from the modal backdrop
  const isClickOutside = event?.target?.classList.contains('modal-backdrop')
  if (!isClickOutside || !props.overlayClickable) {
    return
  }
  toggle(false)
}

watch(show, async value => {
  const body = document.querySelector('body')
  if (value) {
    body.style.overflow = 'hidden'
  } else {
    body.style.overflow = ''
  }

  await nextTick()

  const el = document.getElementById(component.value.id)

  if (!modalCardHeight.value && el) {
    modalCardHeight.value = el.getBoundingClientRect()?.height
  }
})

defineExpose({
  show: show.value,
  toggle
})
</script>
